import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from 'app/core/auth/auth.service';
import { ReadEnterpriseResponse } from '../types/response-types/enterprises/read-enterprise-response.type';
import { UpdateEnterpriseResponse } from '../types/response-types/enterprises/update-enterprise-response.type';
import { Enterprise } from 'app/types/objects/enterprise.type';
import { Customization } from 'app/types/objects/customization.type';
import { Response } from 'app/types/response-types';
import { GetChatUserDto } from 'app/modules/admin/enterprise/dtos/get-chat-user.dto';
import { CreateChatUserDto, UpdateChatUserDto } from 'app/modules/admin/enterprise/dtos';
import { CurrentEnterprisePlan } from 'app/types/objects';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseService {

  public enterprises$: Observable<Array<Enterprise>>;
  public selectedEnterprise$ = new BehaviorSubject(null);

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthService
  ) {
    this.updateEnterprises();
  }

  getCurrentEnterprise(){
    return this._httpClient.get<ReadEnterpriseResponse>(environment.baseUrl + `/enterprises/current`)
    .pipe(
      map((tripResponse: ReadEnterpriseResponse) => {
        // this._tripsCommunication.nextTrip(tripResponse.data);
        return tripResponse.data;
      })
    );
  }

  public getEnterpriseById(enterpriseId: Number): Observable<Enterprise> {
    return this._httpClient.get<ReadEnterpriseResponse>(environment.baseUrl + `/enterprises/${enterpriseId}`)
    .pipe(
      map((tripResponse: ReadEnterpriseResponse) => {
        // this._tripsCommunication.nextTrip(tripResponse.data);
        return tripResponse.data;
      })
    );
  }

  public getAllEnterprises(
    page: number = 1,
    size: number = 10,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    filter: string = ''
  ) {
    return this._httpClient
      .get(environment.baseUrl + '/enterprises/', {
        params: {
          page: '' + page,
          limit: '' + size,
          sort,
          order,
          filterTerm: filter
        }
      })
  }

  public createEnterprise(enterpriseData) {
    return this._httpClient.post(environment.baseUrl + '/enterprises', enterpriseData)
  }

  public updateEnterprises(): void {
    this.enterprises$ = this._httpClient.get(environment.baseUrl + '/user/me/console').pipe(
      switchMap((response: Response<any>) => {
        if (!response.success) {
          return of([]);
        } else {
          if (this._authService.enterpriseId === null) {
            this.select(response.data["enterprises"].filter((enterprise) => enterprise)[0]);
          }
          // this._authService.enterpriseId = response.enterprises.enterpriseId;
          return of(response.data["enterprises"]);
        }
      })
    );
  }

  public modifyEnterprise(enterpriseId: String, req: any): Observable<UpdateEnterpriseResponse> {
    return this._httpClient.put<UpdateEnterpriseResponse>(environment.baseUrl + `/enterprises/${enterpriseId}`, req).pipe(
      map((enterpriseResponse: UpdateEnterpriseResponse) => {
        // this._tripsCommunication.nextTrip(tripResponse.data);
        return enterpriseResponse;
      })
    );
  }

  public deleteEnterprise(enterpriseId) {
    return this._httpClient.delete(environment.baseUrl + `/enterprises/${enterpriseId}`)
  }

  public saveCustomization(customization: Customization): Observable<Response<any>> {
    return this._httpClient.post<Response<any>>(environment.baseUrl + '/enterprises/customization', customization);
  }

  public getChatUsers(getChatUserDto: GetChatUserDto = new GetChatUserDto()): Observable<Response<any>> {
    return this._httpClient.get<Response<any>>(environment.baseUrl + '/enterprises/chat/', {
      params: {
        page: getChatUserDto.page.toString(),
        limit: getChatUserDto.limit.toString(),
        field: getChatUserDto.field,
        order: getChatUserDto.order,
        filter: getChatUserDto.filter
      }
    });
  }

  public createChatUser(createChatUserDto: CreateChatUserDto): Observable<Response<any>> {
    return this._httpClient.post<Response<any>>(environment.baseUrl + '/enterprises/chat', createChatUserDto);
  }

  public updateChatUser(updateChatUser: UpdateChatUserDto): Observable<Response<any>> {
    return this._httpClient.put<Response<any>>(environment.baseUrl + `/enterprises/chat/${updateChatUser.chatEnterpriseId}`, updateChatUser);
  }

  public deleteChatUser(chatEnterpriseId: number): Observable<Response<any>> {
    return this._httpClient.delete<Response<any>>(environment.baseUrl + `/enterprises/chat/${chatEnterpriseId}`);
  }

  public select(enterprise: Enterprise): void {
    this._authService.enterpriseId = String(enterprise.enterpriseId);
    this.selectedEnterprise$.next(enterprise);
  }

  public getEnterpriseCurrentPlans(): Observable<Response<CurrentEnterprisePlan>> {
    return this._httpClient.get<Response<CurrentEnterprisePlan>>(environment.baseUrl + '/enterprises/current-plans/');
  }
}
