import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

export const passengerFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    name: [null, [Validators.required]],
    last_name: ['', [Validators.required]],
    email: [null, [Validators.email]],
    phone: [null, [Validators.pattern(/^[0-9+]+$/)]],
    tripGroupId: [null, [Validators.required]],
    language: [null, []]
  }, 
  { 
    validators: [validateEmailPhone, validateEmailOrPhone]
  });
};

export function validateEmailPhone(control: AbstractControl): ValidationErrors | null {
  const email = control.get('email').value;
  const phone = control.get('phone').value;
  return email !== '' || phone !== '' ? null : { invalid: true };
}


export function validateEmailOrPhone(control: AbstractControl): ValidationErrors | null {
  const email = control.get('email').value;
  const phone = control.get('phone').value;
  return email && email !== "" || phone && phone !== "" ? null : { invalid: true};
}
