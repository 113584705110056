import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { Subject } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconTypeEnum } from './shared/enums/icon-type.enum';
import { environment } from 'environments/environment';
import { NgxPendoService } from 'ngx-pendo';
import { AuthService, LanguageService } from './core';
import { UserService } from './layout/common/user/user.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    _unsubscribeSubject: Subject<void> = new Subject<void>();

    /**
     * Constructor
     */
    constructor(
        private translate: TranslateService,
        private router: Router,
        private sanitizer: DomSanitizer,
        private gtmService: GoogleTagManagerService,
        private iconRegistry: MatIconRegistry,
        protected ngxPendoService: NgxPendoService,
        private authService: AuthService,
        private userService: UserService,
        private languageService: LanguageService
    ) {
        this.languageService.setInitialAppLanguage();
        Object.keys(IconTypeEnum).map(key => {
            const iconName = IconTypeEnum[key];
            this.iconRegistry.addSvgIcon(
                iconName,
                this.sanitizer.bypassSecurityTrustResourceUrl(
                    environment.assetsBaseUrl + '/assets/icons/' + iconName + '.svg'
                )
            );
        });
    }

    ngOnInit(): void {
        this.gtmService.addGtmToDom();
        this.router.events.forEach(item => {
            if (item instanceof NavigationEnd) {
                const gtmTag = {
                    event: 'page',
                    pageName: item.url
                };

                this.gtmService.pushTag(gtmTag);
            }
        });

        this.authService.check().subscribe((res) => {
            if (res) {
                this.userService.user$.subscribe((user) => {
                    
                    if ("userGuiding" in window){
                        const userGuiding = window["userGuiding"] as {
                            identify: (userId: string, data: any) => void;
                        };

                        userGuiding.identify(user['userId'], {
                            name: `${user["name"]} ${user["last_name"]}`,
                            email: user["email"],
                            phone: user["phone"]
                        });
                    }

                    this.ngxPendoService.initialize({
                        id: user['userId'],
                        name: user['name'],
                        last_name: user['last_name'],
                        email: user['email'],
                        phone: user['phone'],
                    }, {
                        id: user['userId'],
                        name: user['name'],
                        last_name: user['last_name'],
                        email: user['email'],
                        phone: user['phone'],
                    });
                });

            }
        })
    }

    ngOnDestroy(): void {
        this._unsubscribeSubject.next();
    }
}
