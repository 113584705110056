export const environment = {
    production: false,
    version: "4.0.0",
    baseUrl: 'https://public-api.viaxlab.com/400',
    storeUrl: 'https://store-api.viaxlab.com/140',
    worldUrl: 'https://world-api-1-0-0.viaxlab.com/',
    chatUrl: 'https://chat-api.viaxlab.com/140/',
    pendoApiKey: 'df4d2810-c7b9-4183-4cd0-0e63cb1a5328',
    assetsBaseUrl: '',
};
