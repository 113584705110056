import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const serviceFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    title: ['', [Validators.required]],
    description: ['', []],
    providerId: ['', []],
    providerName: ['', []],
    imageUrls: [[], []]
  });
};
