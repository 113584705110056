import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export const ItineraryFormGroup = (formBuilder: FormBuilder): FormGroup => {
  return formBuilder.group({
    name: ['', [Validators.required]],
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]],
  }, {
    updateOn: 'blur'
  });
};
