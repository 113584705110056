import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private _httpClient: HttpClient, ) { }

  uploadFile(formData: FormData): Observable<{
    data: {
      name: string;
      url: string;
    };
    errors: [];
    success: boolean;
  }> {
    return this._httpClient.post<{
      data: {
        name: string;
        url: string;
      };
      errors: [];
      success: boolean;
    }>(environment.baseUrl + "user/medical-record/file", formData);
  }
}
