import { Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TreoMediaWatcherService } from '@treo/services/media-watcher';
import { TreoNavigationService } from '@treo/components/navigation';
import { User } from '../../../common/user/user.types';
import { UserService } from "../../../common/user/user.service";
import { EnterpriseService } from "../../../../services/enterprise.service";
import { AuthService } from 'app/core/auth/auth.service';
import { Enterprise } from 'app/types/objects/enterprise.type';
import { environment } from 'environments/environment.prod';
import { SofiaModalComponent } from 'app/shared/dialogs/sofia-modal/sofia-modal.component';
import { MatMenu } from '@angular/material/menu';

@Component({
    selector: 'futuristic-layout',
    templateUrl: './futuristic.component.html',
    styleUrls: ['./futuristic.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuturisticLayoutComponent implements OnInit, OnDestroy {
    version = environment.version;
    data: any;
    user: User;
    enterprises: Array<Enterprise>;
    selectedEnterprise: Enterprise;
    isScreenSmall: boolean;

    @HostBinding('class.fixed-header')
    fixedHeader: boolean;

    @HostBinding('class.fixed-footer')
    fixedFooter: boolean;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {Router} _router
     * @param userService
     * @param enterpriseService
     * @param {TreoMediaWatcherService} _treoMediaWatcherService
     * @param {TreoNavigationService} _treoNavigationService
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private userService: UserService,
        private authService: AuthService,
        private enterpriseService: EnterpriseService,
        private _treoMediaWatcherService: TreoMediaWatcherService,
        private _treoNavigationService: TreoNavigationService,
        private dialog: MatDialog
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Set the defaults
        this.fixedHeader = false;
        this.fixedFooter = false;
    }

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the resolved route data
        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
        });

        this.userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
            });

        this.enterpriseService.enterprises$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((enterprises: Array<Enterprise>) => {
                //this.enterprises = enterprises;
                this.sortEnterprisesAlphabetically(enterprises);
                this.selectedEnterprise = enterprises.filter((enterprise) => String(enterprise.enterpriseId) == this.authService.enterpriseId)[0];
            });

        // Subscribe to media changes
        this._treoMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the breakpoint is 'lt-md'
                this.isScreenSmall = matchingAliases.includes('lt-md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    /**
     * Toggle navigation
     *
     * @param key
     */
    toggleNavigation(key): void {
        // Get the navigation
        const navigation = this._treoNavigationService.getComponent(key);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    sortEnterprisesAlphabetically(enterprises: Array<Enterprise>) {
        this.enterprises = enterprises.sort((a, b) => {
            if (a.enterpriseId === 1) {
                return -1;
            } else if (b.enterpriseId === 1) {
                return 1;
            } else {
                const nameA = a.name.toUpperCase();
                const nameB = b.name.toUpperCase();

                if (nameA < nameB) {
                    return -1;
                } else if (nameA > nameB) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
    }

    openSofiaModal() {
        this.dialog.open(SofiaModalComponent, {
            panelClass: ['modal-container', 'app-full-bleed-dialog'],
            width: "34rem",
            autoFocus: false,
            disableClose: true
        })
    }

    public selectEnterprise(enterprise: Enterprise): void {
        this.authService.enterpriseId = String(enterprise.enterpriseId);
        this.selectedEnterprise = this.enterprises.filter((enterprise) => String(enterprise.enterpriseId) == this.authService.enterpriseId)[0];
    }
}
