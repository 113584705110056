<section id="header">
  <div id="title">{{ "SHARED.DIALOGS.CREATE_POST" | translate }}</div>
</section>
<div *ngIf="isLoading" class="flex justify-between items-center py-2 px-4 h-10 upload-info">
  <span class="text-white">
    Subiendo imágenes
  </span>
  <div class="flex items-center text-white text-secondary">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    {{ uploadedImagesLabel }}
  </div>
</div>
<div *ngIf="isPostUploaded" class="flex justify-between items-center py-2 px-4 h-10 bg-green">
  <div class="flex items-center gap-2 text-white">
    <mat-icon class="text-white">done</mat-icon>
    Post creado exitosamente
  </div>
  <button class="secondary-btn text-white bg-white-25" (click)="closeModal()">
    Cerrar ventana
  </button>
</div>
<div id="main-section">
  <form [formGroup]="this.socialPostForm">
    <div class="gallery-form">
      <mat-form-field class="w-full">
        <mat-label>{{ "SHARED.DIALOGS.GROUPS" | translate }}</mat-label>
        <mat-select formControlName="groups" multiple
          (valueChange)="changeGroup($event)" [ngModel]="this.selectedGroups">
          <mat-option [value]="-1">{{ "SHARED.DIALOGS.ALL" | translate }}</mat-option>
          <mat-option *ngFor="let group of this.groups" [value]="group.tripGroupId">
            {{ group.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>{{ "SHARED.DIALOGS.DESCRIPTION" | translate }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>

      <mat-form-field class="w-full">
        <mat-label>{{ "SHARED.DIALOGS.HASHTAGS" | translate }}</mat-label>
        <input formControlName="tags" matInput (keyup)="formatHashtags($event)" />
      </mat-form-field>

      <div class="w-full">
        <div class="flex gap-2 mb-2">
          <div class="flex items-center mr-1">
            <label class="label mb-0">Imágenes seleccionadas</label>
            <span *ngIf="totalImages > 0" class="text-secondary">&nbsp;({{selectedImages.length}})</span>
          </div>
          <ng-container *ngIf="totalImages > 0 && !isLoading && !isPostUploaded">
            <mat-divider class="my-2" vertical></mat-divider>
            <label secondary-btn for="file" role="button" class="secondary-btn">
              <mat-icon>add_photo_alternate</mat-icon>
              Agregar imágenes
            </label>
            <input class="hidden" id="file" type="file" accept="image/*" multiple (change)="selectFiles($event)"
              formControlName="images" />
          </ng-container>
        </div>
        <div *ngIf="totalImages === 0" class="flex justify-between items-center">
          <span class="text-secondary">
            No hay imágenes seleccionadas
          </span>
          <div>
            <label for="file" class="mat-focus-indicator mat-flat-button mat-button-base mat-primary selector">
              <mat-icon>add_photo_alternate</mat-icon>
              &nbsp;Agregar imágenes
            </label>
            <input class="hidden" id="file" type="file" accept="image/*" multiple (change)="selectFiles($event)"
              formControlName="images" />
          </div>
        </div>
        <div class="divide-y">
          <div *ngFor="let image of selectedImages; index as imageIndex" class="flex justify-between p-2">
            <div class="flex items-center gap-2 w-5/6">
              <mat-icon>image</mat-icon>
              <span class="text-secondary truncate">{{image.originalFile.name}}</span>
            </div>
            <ng-container *ngIf="isLoading || isPostUploaded; else removeImageButton">
              <!-- Loading spinner if image is in loading queue -->
              <div *ngIf="lastUploadedImageIndex !== imageIndex && !image.isUploaded" class="spinner-container">
                <svg class="h-5 w-5 text-gray" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                </svg>
              </div>
              <!-- Loading spinner if image is loading -->
              <div *ngIf="lastUploadedImageIndex === imageIndex" class="spinner-container">
                <svg class="animate-spin h-5 w-5 text-gray" xmlns="http://www.w3.org/2000/svg" fill="none"
                  viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                  </path>
                </svg>
              </div>
              <!-- Success icon when image is uploaded -->
              <ng-container *ngIf="image.isUploaded && lastUploadedImageIndex !== imageIndex">
                <mat-icon class="text-green">check_circle</mat-icon>
              </ng-container>
            </ng-container>
            <ng-template #removeImageButton>
              <button mat-icon-button matTooltip="Remover imagen" (click)="removeImage(imageIndex)">
                <mat-icon svgIcon="close"></mat-icon>
              </button>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- <div class="uploaded-images-wrapper">
    <div class="image-container" *ngFor="let image of this.uploadedImages">
      <div class="image">
        <img [src]="image.url" />
      </div>
    </div>
  </div> -->

</div>

<section id="action-buttons" class="buttons">
  <button mat-button class="mat-focus-indicator mat-button mat-button-base mat-warn" (click)="closeModal()">
    Cerrar
  </button>

  <button mat-flat-button color="primary" (click)="createPost()"
    [disabled]="isLoading || isPostUploaded || socialPostForm.invalid">
    Crear Post
  </button>
</section>