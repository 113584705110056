import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Code, Itinerary, PaymentProcessor } from 'app/types/objects';
import { Response, SingleResponse } from 'app/types/response-types';
import { environment } from 'environments/environment';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

export interface TripProposalPayload {
  title: string;
  information: string;
  itineraryId?: number;
  activityIds?: number[];
  generalConditions?: string[];
  financing: boolean;
  installmentsQuantity?: number;
  price: number;
  currency: string;
  priceClarifications?: string;
  paymentMethods?: string[],
}

@Injectable({
  providedIn: 'root'
})
export class TripProposalService {

  constructor(private _httpClient: HttpClient) { }

  getCurrencies(): Observable<Response<Code>> {
    return this._httpClient.get<Response<Code>>(environment.baseUrl + '/codes/', {
      params: {
        code: 'currency',
        limit: '' + 10000,
        page: '' + 1
      }
    })
  }

  getItineraries(tripId: number): Observable<Response<Itinerary>> {
    return this._httpClient.get<Response<Itinerary>>(environment.baseUrl + `/trips/${tripId}/itineraries`);
  }

  createProposal(tripId: number, payload: TripProposalPayload): Observable<SingleResponse<{ url: string }>> {
    return this._httpClient.post<SingleResponse<{ url: string }>>(environment.baseUrl + `/trips/${tripId}/proposals`, payload);
  }
}
