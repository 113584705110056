// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: "4.0.0",
  baseUrl: 'https://public-api.viaxlab.com/400',
  storeUrl: 'https://store-api.viaxlab.com/140',
  worldUrl: 'https://world-api-1-0-0.viaxlab.com/',
  chatUrl: 'https://chat-api.viaxlab.com/140/',
  pendoApiKey: 'df4d2810-c7b9-4183-4cd0-0e63cb1a5328',
  assetsBaseUrl: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
