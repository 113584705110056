export enum IconTypeEnum {
  ITINERARIES= 'itineraries',
  CLOCK= 'clock',
  SAND_CLOCK= 'sand_clock',
  GROUP_PLUS= 'group_plus',
  GROUP_CHECKED= 'group_checked',
  COMMENTS= 'comments',
  WEATHER_CLEAR= 'weather_clear',
  WEATHER_CLOUDS= 'weather_clouds',
  WEATHER_RAIN= 'weather_rain',
  WEATHER_DRIZZLE= 'weather_drizzle',
  WEATHER_SNOW= 'weather_snow',
  WEATHER_THUNDERSTORM= 'weather_thunderstorm',
}