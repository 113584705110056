<!-- Button -->
<button
  mat-mini-fab
  [matMenuTriggerFor]="notificationsMenu"
  [matBadge]="_notificationsService.totalUnreadNotifications"
  [matBadgeHidden]="!_notificationsService.totalUnreadNotifications"
  matBadgeColor="warn"
  matBadgeSize="small"
  matTooltip="Notificaciones"
  matTooltipPosition="right"
  class="notifications-button"
>
  <mat-icon>notifications</mat-icon>
</button>
<mat-menu class="user-actions-menu" xPosition="before" #notificationsMenu="matMenu">
  <div class="flex justify-between items-center pb-4 pt-2 px-4 header" (click)="$event.stopPropagation()">
    <div class="font-semibold">Notificaciones</div>
    <button
      class="secondary-btn"
      [matBadge]="_notificationsService.totalUnreadNotifications"
      [matBadgeHidden]="!_notificationsService.totalUnreadNotifications"
      matBadgeColor="warn"
      matBadgeSize="small"
      routerLink="/user-notifications"
      (click)="closeMenu()"
    >
      Ver todas
    </button>
  </div>

  <ng-container *ngIf="_notificationsService.error; else notificationsList">
    <div class="py-8 px-4">
      <app-error-state text="Ha ocurrido un error al cargar las notificaciones"></app-error-state>
    </div>
  </ng-container>
  <ng-template #notificationsList>
    <ng-container *ngIf="(_notificationsService.lastUserNotifications | async).length; else noNotifications">
      <button
        *ngFor="let notification of _notificationsService.lastUserNotifications | async"
        mat-menu-item
        class="relative cursor-pointer"
        [class.unread]="!notification.readAt"
        (click)="_notificationsService.openNotification(notification)"
      >
        <mat-icon>{{ notification.iconName }}</mat-icon>
        <div class="py-2">
          <div class="font-medium">{{ notification.title }}</div>
          <div class="mb-1">{{ notification.content }}</div>
          <span class="text-secondary text-xs">{{ notification.timeAgo }}</span>
        </div>
        <div *ngIf="!notification.readAt" class="rounded-full w-2 h-2 bg-red absolute unread-dot"></div>
      </button>
    </ng-container>
    <ng-template #noNotifications>
      <div class="p-8">
        <app-empty-state iconName="notifications_off" text="Aún no hay notificaciones" small="true"></app-empty-state>
      </div>
    </ng-template>
  </ng-template>
</mat-menu>
