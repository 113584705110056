<section id="header" [class.loading]="this.isLoading">
    <div id="title">
        Sofía <mat-icon>face_retouching_natural</mat-icon>
    </div>
    <mat-icon [svgIcon]="'close'" (click)="close()"> </mat-icon>
</section>

<section id="main-section" [class.loading]="this.isLoading">
    <div class="destination-wrapper" *ngIf="this.activeComponent == 'DESTINATION'">
        <form [formGroup]="this.form">
            <mat-form-field>
                <mat-label>¿Para tu viaje a qué destino necesitas ayuda?</mat-label>
                <input formControlName="destination" matInput autocomplete="off">
            </mat-form-field>
            <button mat-flat-button color="primary" [disabled]="!this.form.valid"
                (click)="this.saveDestination()">Iniciar conversación</button>
        </form>
    </div>
    <div class="chat-wrapper" *ngIf="this.activeComponent == 'CHAT'">
        <div class="chat" #chat></div>
    </div>
</section>

<section id="action-buttons" class="buttons">
    <div class="flex-end w-6/6">
        <button mat-button class="mat-focus-indicator mat-button mat-button-base" color="primary" (click)="this.close()">
            No necesito ayuda ahora
        </button>
    </div>
</section>

<div class="loader" *ngIf="this.isLoading">
    <mat-spinner></mat-spinner>
</div>