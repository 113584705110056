import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AiService } from '../../../services/ai.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'sofia-modal',
    templateUrl: './sofia-modal.component.html',
    styleUrls: ['./sofia-modal.component.scss']
})
export class SofiaModalComponent implements OnInit {
    form: FormGroup = this.formBuilder.group({
        destination: ['', Validators.required],
    });

    isLoading: boolean = false;
    @ViewChild('chat') chat: ElementRef;
    destination: string;
    activeComponent: string = "DESTINATION";

    options: any[] = [
        {
            label: "🛒 ¿Cómo podemos vender el viaje a más pasajeros?",
            id: 1
        },
        {
            label: "💡 ¿Qué productos opcionales puedo agregar a la tienda de Viaxab?",
            id: 2
        },
        {
            label: "💌 ¿Cómo puedo comunicarle la app Viaxlab a mis pasajeros?",
            id: 3
        },
        {
            label: "🌟 ¿Cuáles son las actividades más populares en el lugar?",
            id: 4
        },
        {
            label: "💎 ¿Qué actividades distintas podemos hacer para diferenciarnos?",
            id: 5
        },
        {
            label: "🎯 ¿Cómo podemos comunicar efectivamente el viaje en redes sociales?",
            id: 6
        }
    ]

    constructor(
        public matDialogRef: MatDialogRef<SofiaModalComponent>,
        private formBuilder: FormBuilder,
        private _aiService: AiService,
        private renderer: Renderer2
    ) { }

    ngOnInit(): void {

    }

    saveDestination() {
        this.destination = this.form.get("destination").value;
        this.activeComponent = "CHAT"
        setTimeout(() => {
            this.setInitalContent();
        }, 1000)
    }

    setInitalContent() {
        this.showResponse(`Genial, estoy lista para ayudarte con tu viaje a ${this.destination}, ¿qué necesitas?`)
            .then((response) => {
                this.showOptions();
            });
    }

    showOptions() {
        const div: HTMLSpanElement = this.renderer.createElement('div');
        div.classList.add("options");
        this.renderer.appendChild(this.chat.nativeElement, div);
        for (let option of this.options) {
            const span: HTMLSpanElement = this.renderer.createElement('span');
            span.classList.add("line")
            span.classList.add("clickeable")
            span.innerHTML = option.label
            span.addEventListener('click', this.selectOption.bind(this, option));
            this.renderer.appendChild(div, span)
        }
    }

    selectOption(option) {
        this.isLoading = true;
        const elements = document.getElementsByClassName("line");

        for (var index = 0; index < elements.length; index++) {
            const element = elements[index];
            element.classList.remove("clickeable")
            element.classList.add("disabled")
            const newElement = element.cloneNode(true);
            element.parentNode.replaceChild(newElement, element);
        }

        this._aiService.help(this.destination, option.id)
            .subscribe({
                next: response => {
                    this.isLoading = false;
                    this.showResponse(response.data)
                        .then(() => {
                            this.showOptions();
                        })
                }
            })
    }

    showResponse(text) {
        const div: HTMLSpanElement = this.renderer.createElement('div');
        div.classList.add("response");
        this.renderer.appendChild(this.chat.nativeElement, div);

        return new Promise((resolve, reject) => {
            let pos = 0;
            const interval = setInterval(() => {
                if (pos >= text.length) {
                    clearInterval(interval);
                    resolve(true);
                } else {
                    div.insertAdjacentHTML('beforeend', text[pos])
                }
                pos++;
            }, 25)
        })
    }

    close(): void {
        this.matDialogRef.close();
    }

}
