import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { CreateServiceResponse, Response, UpdateServiceResponse, ReadServiceResponse, SingleResponse } from '../types/response-types';
import { Service } from '../types/objects';
import { LIST_PAGE_SIZE_DEFAULT } from '../shared';
import { ServicesCommunicationService } from './services-communication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MediaService } from './media.service';
import { AlertsService } from './alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  constructor(
    private _httpClient: HttpClient,
    private _servicesCommunication: ServicesCommunicationService,
    private _mediaService: MediaService,
    private _alertService: AlertsService,
    private _translateService: TranslateService,
    private _snackBar: MatSnackBar
  ) { }

  updateService(id: number, service: any): Observable<UpdateServiceResponse> {
    return this._httpClient.put<UpdateServiceResponse>(environment.baseUrl + `/services/${id}`, service)
  }

  deleteService(serviceId): Observable<boolean> {
    return this._httpClient.delete<any>(environment.baseUrl + `/services/${serviceId}`);
  }

  generateDescription(tripId: number, title: string): Observable<SingleResponse<string>> {
    return this._httpClient.get<SingleResponse<string>>(environment.baseUrl + `/trips/${tripId}/services/description/`, {
      params: {
        title: title
      }
    });
  }

  getServices(
    page: number = 1,
    size: number = LIST_PAGE_SIZE_DEFAULT,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    filter: string = ''
  ): Observable<Response<Service>> {

    return this._httpClient
      .get<Response<Service>>(environment.baseUrl + '/services/', {
        params: {
          page: '' + page,
          limit: '' + size,
          sort,
          order,
          'filter': filter
        }
      })
  }

  getSuggestedServices(tripId: number, term: string): Observable<Response<Service>> {
    return this._httpClient.get<Response<Service>>(environment.baseUrl + `/trips/${tripId}/services/suggest/`, {
      params: {
        "term": term
      }
    });
  }

  getServiceById(id: number): Observable<Service> {
    return this._httpClient.get<ReadServiceResponse>(environment.baseUrl + `/services/${id}`).pipe(
      map((serviceResponse: ReadServiceResponse) => {
        this._servicesCommunication.nextService(serviceResponse.data);
        return serviceResponse.data;
      })
    );
  }

  createService(service: any): Observable<CreateServiceResponse> {
    return this._httpClient.post<CreateServiceResponse>(environment.baseUrl + `/services`, service)
  }

  uploadImage(file: File): Observable<any> {
    const formData = new FormData();
    this._mediaService.compressImage(file)
    .subscribe((compressedFile) => {
      formData.append("file", compressedFile);
      formData.append("resource_type", "service-images");
    });
    return this._httpClient.post<any>(`${environment.baseUrl}/images/upload`, formData);
  }

  uploadOriginalImage(file: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("resource_type", "service-images");
    return this._httpClient.post<any>(`${environment.baseUrl}/images/upload`, formData);
  }

  getEditorConfig() {
    return {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: '',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        { class: 'arial', name: 'Arial' },
        { class: 'times-new-roman', name: 'Times New Roman' },
        { class: 'calibri', name: 'Calibri' },
        { class: 'comic-sans-ms', name: 'Comic Sans MS' }
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      upload: (file: File) => new Observable((observer) => {
        if (file.size >= 5000000) {
          this._alertService.showSnackbarAlert(this._translateService.instant('SHARED.DIALOGS.MAX_FILE_SIZE'));
        } else {
          this._snackBar.open(this._translateService.instant('SHARED.DIALOGS.UPLOADING'), null, { duration: 1000000, horizontalPosition: 'start' });
          this.uploadOriginalImage(file)
            .subscribe((response) => {
              setTimeout(() => this._snackBar._openedSnackBarRef.dismiss(), 1000);
              observer.next({ "body": { "imageUrl": response.data.url } });
              observer.complete();
            })
        }
      }),
      uploadWithCredentials: false,
      sanitize: false,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        [],
        ['toggleEditorMode', 'customClasses', 'fontName']
      ]
    };
  }
}
